import storeInstance from "./getStorageInstance";

function getToken() {
  return storeInstance.getItem("token");
}

function getUserId() {
  return storeInstance.getItem("userId");
}

function getUserMeta() {
  return storeInstance.getItem("userMeta");
}

export { getToken, getUserId, getUserMeta };
