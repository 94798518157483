import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
	const [visibleLoader, setVisibleLoader] = useState(false);
	const [userData, setUserData] = useState();
	const [loggedIn, setLoggedIn] = useState(false);
	const [userId, setUserId] = useState();
	const [isParent, setIsParent] = useState(false);
	const [allDomainLocation, setAllDomainLocation] = useState(0);
	const [domainTitle, setDomainTitle] = useState('');
	const value = useMemo(
		() => ({
			visibleLoader,
			setVisibleLoader,
			userData,
			setUserData,
			loggedIn,
			setLoggedIn,
			userId,
			setUserId,
			isParent,
			setIsParent,
			allDomainLocation,
			setAllDomainLocation,
			domainTitle,
			setDomainTitle
		}),
		[visibleLoader, userData, loggedIn, userId, isParent, allDomainLocation, domainTitle],
	);
	return (
		<GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
	);
};

GlobalProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default GlobalContext;
export { GlobalProvider };
