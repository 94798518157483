import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import Cookies from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation } from 'react-router-dom';

import {
  getUserMetaData,
  setTokenInClient,
} from '../../apis';
import GlobalContext from '../../context/GlobalContext';
import Routes from '../../routes/Routes';
import {
  getToken,
  getUserId,
  getUserMeta,
} from '../../utils/functions/getStorageObject';
import { initUserAfterAuth } from '../../utils/functions/misc';
import { setUserMeta } from '../../utils/functions/setStorageObject';

// Import the below lines if you want to enable multiple themes
// import theme from "../../utils";
// import { ThemeProvider } from "styled-components";

const Layout = React.memo(() => {
	const [showLoader, setShowLoader] = useState(true);
	const { loggedIn, setLoggedIn, userData, setUserData, setUserId } =
		useContext(GlobalContext);
	const getUser = useCallback(async () => {
		let userData;
		try {
			userData = await getUserMeta();
			const userId = await getUserId();
			setUserId(userId);
			Cookies.set('userId', userId, {domain:'cogmentor.com', sameSite: 'Strict', secure: true}); 
			if (!userData) {
				const token = await getToken();
				await setTokenInClient({ token });
				const response = await getUserMetaData({ userId });
				const userMeta = response?.data?.data;
				if (userMeta) {
					await initUserAfterAuth({ userMeta }); 
					userData = userMeta;
				}
			}
		} catch (error) {
			console.error(error);
		}

		return userData;
	}, [setUserId]);
	const location = useLocation();
	const { pathname } = location || {};
	useEffect(() => {
		if (userData) {
			setShowLoader(false);
		} else {
			setShowLoader(true);
			getUser()
				.then((user) => {
					if (user) {
						setUserData(user);
						setLoggedIn(true);
					}
				})
				.catch(console.error)
				.then(() => setShowLoader(false));
		}
	}, [setLoggedIn, setUserData, getUser, pathname, userData]);
	const refetchUser = useCallback(() => {
		setUserMeta({ userMeta: undefined })
			.then(() => getUser())
			.then((user) => {
				if (user) {
					setUserData(user);
					setLoggedIn(true);
				}
			})
			.catch(console.error);
	}, [getUser, setLoggedIn, setUserData]);
	useEffect(() => {
		global.document.addEventListener('refetch-user', refetchUser);
		return () =>
			global.document.removeEventListener('refetch-user', refetchUser);
	}, [refetchUser]);
	useEffect(() => {
		if (loggedIn) {
			global.document.body.classList.add('bg-light');
		} else {
			global.document.body.classList.remove('bg-light');
		}
	}, [loggedIn]);
	if (showLoader) {
		return (
			<div className="vh-100 d-flex justify-content-center align-items-center bg-white">
				<Spinner
					as="span"
					animation="border"
					size="sm"
					role="status"
					className="mr-3"
					aria-hidden="true"
				/>
				Loading...
			</div>
		);
	}

	return <Routes loggedIn={loggedIn} />;
});

Layout.displayName = 'Layout';

export default Layout;
